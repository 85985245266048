import * as articles from '../../paragraphs/articles.json'
import CardComponent from './CardComponent'

export const ArticleCards = articles.publications.map(article => {
    return <CardComponent
        title={article.title}
        subtitle={article.subtitle}
        preview={article.content.p[0]}
        id={article.id}
        key={article.id}
        alt={article.image.alt}
        category={article.category}
    />
})