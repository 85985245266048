import * as strings from '../../paragraphs/strings.json'
import {useState, useEffect} from 'react'

function Cycler() {

    const [index, setIndex] = useState(0)

    function incrementIndex() {
        setIndex(prevIndex => {
            if(prevIndex === strings.header.length - 1) {
                return 0;
            } else {
                return prevIndex + 1;
            }
        })
    }

    useEffect(() => {
        setInterval(() => {
            incrementIndex();
        }, 5000)
    }, [])


    return (
        <h1 className="cycler-header">{strings.header[index]}</h1>
    )
}

export default Cycler