import NavBubble from './children/NavBubble'

import { ReactComponent as HomeSVG } from '../icons/home.svg'
import { ReactComponent as MusicSVG } from '../icons/music.svg'
import { ReactComponent as PodcastSVG } from '../icons/podcast.svg'
import { ReactComponent as ArticlesSVG } from '../icons/articles.svg'
import { ReactComponent as ContactSVG } from '../icons/contact.svg'

import { useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

function Nav() {

    let location = useLocation()

    // TODO: CREATE CSS CLASS TO HIDE AND SHOW NAV BASED ON SIZE
    // CREATE ANOTHER NAV FOR MOBILE
    const destinations = [{
        key: 0,
        name: "Home",
        destination: "/",
        exact: true,
        svg: <HomeSVG/>
    }, {
        key: 1,
        name: "Music",
        destination: "/music",
        exact: false,
        svg: <MusicSVG />
    }, {
        key: 2,
        name: "Articles",
        destination: "/articles",
        exact: false,
        svg: <ArticlesSVG/>
    }, {
        key: 3,
        name: "Podcast",
        destination: "/podcast",
        exact: false,
        svg: <PodcastSVG/>
    }, {
        key: 4,
        name: "Contact",
        destination: "/contact",
        exact: false,
        svg: <ContactSVG/>
    }]

    const [current, setCurrent] = useState(destinations[0])
    const [isHidden, setHidden] = useState(true)

    const otherDestinations = destinations.map(destination => {
        if (destination.name !== current.name)
            return (
                <NavLink
                    exact to={destination.destination}
                    activeClassName="dropdown-selected"
                    onClick={() => navItemSelected(destination)}
                    activeStyle={{textDecoration: "none"}}
                >
                    <div className="dropdown-item">
                        <h2>{destination.name}</h2>
                        {destination.svg}
                    </div>
                </NavLink>
            )
    })

    useEffect(() => {
        setCurrent(() => {
            const match = destinations.filter(destination => destination.destination === location.pathname)
            return match[0]
        })
    }, [])


    // sets the current destination and closes the box
    function navItemSelected(des) {
        setCurrent(des)
        setHidden(true)
    }

    //dropdown selected determines which page is active
    //current needs to be set to dropdown selected
    useEffect(() => {
        const selected = document.getElementsByClassName("dropdown-selected")
        const newCurrent = destinations.filter(destination => destination.name == selected[0].innerText)
        if (newCurrent[0].name !== current.name) {
            setCurrent(newCurrent[0])
        }
    })


    return (
        <div>
            <nav className="big-nav">
                <NavBubble icon={<HomeSVG />} destination="/" exact={true} />
                <NavBubble icon={<MusicSVG />} destination="/music" />
                <NavBubble icon={<PodcastSVG />} destination="/podcast" />
                <NavBubble icon={<ArticlesSVG />} destination="/articles" />
                <NavBubble icon={<ContactSVG />} destination="/contact" />
            </nav>
            <nav className="small-nav">
                <NavLink exact to={current.destination} activeClassName="dropdown-selected">
                    <div className="small-nav-current-container" onClick={() => setHidden(prev => !prev)}>
                        <h2 className="small-nav-current">{current.name}</h2>
                        {current.svg}
                    </div>
                </NavLink>
                <div className="dropdown-container">
                    <div className={`dropdown-items ${isHidden ? "hidden" : ""}`}>
                        {otherDestinations}
                    </div>
                </div>
            </nav>
        </div>

    )
}

export default Nav