import { NavLink } from 'react-router-dom'

function NavBubble(props) {

    if (props.exact) {
        return (
            <NavLink exact to={props.destination} activeClassName="nav-selected">
                <div className="nav-container">
                    {props.icon}
                </div>
            </NavLink>
        )
    } else {
        return (
            <NavLink to={props.destination} activeClassName="nav-selected">
                <div className="nav-container">
                    {props.icon}
                </div>
            </NavLink>
        )
    }


}

export default NavBubble