import { useLocation } from 'react-router-dom'
import { useState, useEffect } from 'react'
import * as articles from '../../paragraphs/articles.json'
import reverse from "../../files/Reversing_Minesweeper.pdf"

function Article(props) {

    let location = useLocation()
    const [scrollDest, setScrollDest] = useState(0)
    const [article, setArticle] = useState("Productivity Hours")
    const [articleHTML, setArticleHTML] = useState()

    const articleName = props.location.hash.substr(1)

    useEffect(() => {


        // scroll to element after rerender
        const currentLocation = location.hash.substr(1)
        setArticle(currentLocation)

        if (currentLocation) {
            const scrollDestination = document.getElementById(currentLocation)
            setScrollDest(scrollDestination.offsetTop)
            window.scrollTo(0, scrollDest)
        }

        // select new article
        const selectedArticle = articles.publications.filter(entry => entry.title === article)

        if (selectedArticle.length === 1) {
            const { headings, p, order } = selectedArticle[0].content

            let a = 0, b = 0;

            if (selectedArticle[0].type === "internal") {

                const renderedArticle = order.map(index => {
                    if (index === "h") {
                        return <h2>{headings[a++]}</h2>
                    } else if (index === "p") {
                        return <p>{p[b++]}</p>
                    }
                    return null
                })

                setArticleHTML(renderedArticle)

            } else if (selectedArticle[0].type === "ebook") {
                setArticleHTML(
                    <div className="ebook">
                        <div className="ebook_description">
                            <p>{p[0]}</p>
                            <p><strong>To read the full article, you can purchase it on <a href={`https://www.amazon.com/dp/${selectedArticle[0].ASIN}`}>Amazon</a></strong></p>
                        </div>
                        <iframe
                            title={selectedArticle[0].title}
                            type="text/html"
                            width="212"
                            height="362"
                            frameborder="0"
                            allowfullscreen
                            src={`https://read.amazon.com/kp/card?asin=${selectedArticle[0].ASIN}&preview=inline&linkCode=kpe&ref_=cm_sw_r_kb_dp_YXXN9SSEC2JXFNV0EMV9`} >
                        </iframe>
                    </div>

                )
            } else if (selectedArticle[0].type === "external") {
                setArticleHTML(
                    <div className="external">
                        <p>{p[1]}</p>
                        <p><strong><a href={selectedArticle[0].link}>Click the here to checkout the full article</a></strong></p>
                    </div>
                )
            } else if (selectedArticle[0].type === "file") {
                setArticleHTML(
                    <div>
                        <p>{selectedArticle[0].content.p[0]}</p>
                    <embed className="pdf"
                        src={reverse}
                        type="application/pdf"
                        height="100%"
                        width="100%">
                    </embed>
                    </div>

                )
            }
        }

    }, [location, scrollDest, article])

    return (
        <div className="end-to-end-bg">
            <div className="container" id={articleName}>
                <h1 className="article-heading">{articleName}</h1>
                {articleHTML}
            </div>
        </div>
    )
}

export default Article