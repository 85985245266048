import { Switch, Route} from 'react-router-dom'

import Header from './Header'
import Home from './Home'
import Podcast from './Podcast'
import Music from './Music'
import Articles from './Articles'
import Contact from './Contact'


import Nav from './Nav'
import Footer from './Footer'



function App() {

    return (
        
        <div className="max-window">
            <Header />
            <div className="main-content">
                <Switch>
                    <Route exact path="/"><Home/></Route>
                    <Route path="/music"><Music/></Route>
                    <Route path="/podcast"><Podcast/></Route>
                    <Route path="/articles"><Articles/></Route>
                    <Route path="/contact"><Contact /></Route>
                </Switch>
            </div>
            <Footer />
        </div>


    )
}

export default App