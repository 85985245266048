import HeaderImage from '../images/Danolanater_Logo.png'
import Cycler from '../components/children/Cycler'

import Nav from './Nav'

function Header() {
    return (
        <header>
            <div className="container">
                <div className="header-row">
                    <img src={HeaderImage} alt="Nolan's face in the 'O' of 'Danolanater'" />
                    <div className="cycler-container">
                        <Cycler />
                    </div>
                </div>
                <Nav />
            </div>
        </header>
    )
}

export default Header