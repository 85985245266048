import { ReactComponent as LeftQuote } from '../../icons/quote_left.svg'
import { ReactComponent as RightQuote } from '../../icons/quote_right.svg'

function QandAComponent(props) {
    return (
        <div className="qAndA">
            <p className="question">{props.question}</p>
            <p className="answer">
                <LeftQuote /> {props.answer} <RightQuote />
            </p>
        </div>
    )
}

export default QandAComponent