import * as strings from '../paragraphs/strings.json'
import { ArticleCards as articleCards } from '../components/children/ArticleCards'
import { NavLink } from 'react-router-dom'

function Home() {

    const recentPosts = articleCards.filter((card, index) => index < 3)

    return (
        <div className="home">
            <div className="spacer"></div>
            <div className="container">
                <h1 className="page-header">Home</h1>
                <div>
                    <div className="split-row">
                        <h3>Recent Posts</h3>
                        <NavLink exact to="/articles" className="view-button"> View All Publications</NavLink>
                    </div>

                    <div className="post-cards">
                        {recentPosts}
                    </div>
                </div>

            </div>
            <div className="spacer"></div>
            <div className="end-to-end-bg">
                <div className="container">
                    <div className="home-inspiration">
                        <h3>Inspiration for this Site</h3>
                        <p>{strings.home.p[0]}</p>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default Home