import { ReactComponent as InstagramSVG } from '../icons/instagram_small.svg'
import { ReactComponent as GithubSVG } from '../icons/github_small.svg'
import { ReactComponent as SpotifySVG } from '../icons/spotify_small.svg'
import { ReactComponent as LinkedinSVG } from '../icons/linkedin_small.svg'
import { ReactComponent as SoundcloudSVG } from '../icons/soundcloud_small.svg'

import Modal from './children/Modal'

import ReCAPTCHA from "react-google-recaptcha";
import emailjs from 'emailjs-com'

import { useState, useEffect } from 'react'

function Contact() {

    // form data
    const [isHuman, setIsHuman] = useState(false)
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [subject, setSubject] = useState("")
    const [body, setBody] = useState("")
    const [isCopy, setIsCopy] = useState(false)
    const [size, setSize] = useState("normal")
    const [isSending, setIsSending] = useState(false)
    const [isSuccess, setIsSuccess] = useState("pending")

    const linkObjs = [
        { icon: <InstagramSVG />, userName: "Danolanater", site: "Instagram", url: "https://www.instagram.com/danolanater/" },
        { icon: <GithubSVG />, userName: "Danolanater", site: "GitHub", url: "https://github.com/danolanater" },
        { icon: <SpotifySVG />, userName: "Wild_and_Mild", site: "Spotify", url: "https://open.spotify.com/show/60iA6P5JC3apwEkUW0kd69" },
        { icon: <LinkedinSVG />, userName: "nolanroe", site: "LinkedIn", url: "https://www.linkedin.com/in/nolanroe/" },
        { icon: <SoundcloudSVG />, userName: "Danolanater", site: "SoundCloud", url: "https://soundcloud.com/danolanater" }]

    const quickLinks = linkObjs.map(pair => {
        return (
            <div className="quick-link">
                <a className="quick-link-a" href={pair.url}>
                    <p className="quick-link_site">{pair.site}</p>
                    {pair.icon}
                    <p className="quick-link_user">@{pair.userName}</p>
                </a>
            </div>
        )
    })

    function sendEmail(event) {
        event.preventDefault()

        if (!isHuman) {
            console.log("So, you're not a human?")
        } else {
            const data = {
                service_id: 'service_c4mry42',
                template_id: 'template_np68dnk',
                user_id: 'user_VC9kzT9YEEmu1dov9y3c6',
                template_params: {
                    from_name: name,
                    from_email: email,
                    subject: subject,
                    message: body
                }
            }

            if (isCopy) {
                data.template_params = {
                    ...data.template_params,
                    cc_email: email
                }
            }

            setIsSending(true)

            emailjs.send(data.service_id, data.template_id, data.template_params, data.user_id)
                .then(result => {
                    if (result.text === "OK") {
                        setIsSuccess("yes")
                        const inputs = document.getElementsByTagName("input")
                        const textarea = document.getElementsByTagName("textarea")
                        Array.from(inputs).forEach(item => {
                            if (item.type === "checkbox") {
                                item.checked = false
                            } else {
                                item.value = ""
                            }
                        })
                        Array.from(textarea)[0].value = ""
                        setName("")
                        setEmail("")
                        setSubject("")
                        setBody("")
                        setIsCopy(false)
                    } else {
                        setIsSuccess("no")
                    }
                    setIsSending(false)
                })
        }
    }

    useEffect(() => {
        const modal = document.getElementsByClassName("modal-faded")[0]
        if(isSending) {
            modal.classList.add("show")
        } else {
            // display sending code here
            setTimeout(() => {
                modal.classList.remove("show")
            }, 3000)
            setTimeout(() => {
                setIsSuccess("pending")
            }, 3500)
        }
    }, [isSending])

    useEffect(() => {
        const modal = document.getElementsByClassName("modal-faded")[0]
        if(isSuccess === "yes") {
            modal.textContent = "Email Sent Successfully!"
        } else if (isSuccess === "no" ){
            modal.textContent = "There was an error. Please try again later..."
        } else {
            modal.textContent = "Sending..."
        }

    }, [isSuccess])

    function handleChange(event) {

        const { name, value, checked, type } = event.target

        if (type !== "checkbox") {
            switch (name) {
                case "name":
                    setName(value)
                    break;
                case "email":
                    setEmail(value)
                    break;
                case "subject":
                    setSubject(value)
                    break;
                case "body":
                    setBody(value)
                    break;
                default:
            }
        } else {
            setIsCopy(checked)
        }

    }

    return (
        <div className="container">
            <Modal />
            <div className="contact-row">
                <div className="half">
                    <h2>Social Media</h2>
                    <div className="contact-box">
                        {quickLinks}
                    </div>
                </div>
                <div className="half">
                    <h2>Send A Message</h2>
                    <form className="contact-box" onSubmit={sendEmail}>
                        <input id="name" required name="name" type="text" placeholder="Your Name" onChange={handleChange} /><br />
                        <input id="email" required name="email" type="email" placeholder="Email" onChange={handleChange} /><br />
                        <input id="subject" required name="subject" type="text" placeholder="Subject" onChange={handleChange} /><br />
                        <textarea id="message" required name="body" rows="10" placeholder="Type your message here..." onChange={handleChange} /><br />
                        <div className="captcha-container">
                            <ReCAPTCHA
                                sitekey="6Leuu5gaAAAAAOVlTfbIa8GusyB9h9xpzjRPLXEW"
                                onChange={() => setIsHuman(true)}
                                onExpired={() => setIsHuman(false)}
                                size={size}
                                theme="dark"
                            />
                        </div>
                        <br />
                        <div className="submit-row">
                            <button type="submit">Submit</button>
                            <div className="row">
                                <input id="copy" type="checkbox" onChange={handleChange} />
                                <label> Send a copy to your email</label>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Contact