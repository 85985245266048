import { images } from '../../images/images'
import { Link } from 'react-router-dom'
import { useState } from 'react'

function CardComponent(props) {

    const mobileCard =

        <div className={`card-container ${props.category}`}>
            <Link to={{ pathname: "articles", hash: props.title }} className="mobile-card-link" >
                <div className="card-header">
                    <h3 className="card-title">{props.title}</h3>
                    <p className="card-description">{props.subtitle}</p>

                    <div className="bar2"></div>
                </div>
                <img className="card-image" src={images[props.id]} alt={props.alt} />

                <p className="card-button">Click to Read More</p>

                <p className="card-type">{props.category}</p>
            </Link>
        </div>

    const largeCard =
        <div className={`card-container ${props.category}`}>
            <div className="card-header">
                <h3 className="card-title">{props.title}</h3>
                <p className="card-description">{props.subtitle}</p>
            </div>
            <div className="spacer"></div>
            <div className="card-animation-area">
                <div className="card-image-replacement">
                    <p className="card-paragraph">{props.preview}</p>
                    <Link to={{ pathname: "articles", hash: props.title }} className="card-button">
                        Click to Read More
                    </Link>
                </div>
            </div>
            <img className="card-image" src={images[props.id]} alt={props.alt} />
        </div>

    const smallCard =
        <div className={`card-container ${props.category}`}>
            <div className="card-column">
                <div className="card-header">
                    <h3 className="card-title">{props.title}</h3>
                    <p className="card-description">{props.subtitle}</p>
                </div>
                <p className="card-paragraph">{props.preview}</p>
                <div className="spacer"></div>
                <Link to={{ pathname: "articles", hash: props.title }} className="card-button">
                    Click to Read More
                </Link>
            </div>
            <img className="card-image" src={images[props.id]} alt={props.alt} />
        </div>

    const mql1 = window.matchMedia("(min-width: 951px)")
    const mql2 = window.matchMedia("(max-width: 550px)")

    const [isMobile, setMobile] = useState(mql2.matches)
    const [isLarge, setLarge] = useState(mql1.matches)

    mql1.addEventListener('change', e => {
        setLarge(e.matches)
    })

    mql2.addEventListener('change', e => {
        setMobile(e.matches)
    })

    if (isMobile) {
        return mobileCard
    } else if (isLarge) {
        return largeCard
    } else {
        return smallCard
    }

}

export default CardComponent