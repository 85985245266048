import * as strings from '../paragraphs/strings.json'
import GuitarJpg from '../images/cinco_de_mayo2011.jpg'
import QandAComponent from './children/QandAComponent'

function Music() {

    const qAndAs = strings.music.question.map((question, answer) => {
        return <QandAComponent question={question} answer={strings.music.answer[answer]} key={answer}/>
    })

    return (
        <div className="music">
            <div className="container">
                <h1 className="page-header">Music</h1>
                <h3 className="music-subtitle">~Everyone needs a hobby, don't judge me...</h3>

                <div className="row">
                    <img className="music-image" src={GuitarJpg} alt="Nolan playing guitar shirtless in sobrerro" />
                    <iframe
                        title="SoundCloud Player"
                        width="45%"
                        height="300"
                        scrolling="no"
                        frameborder="no"
                        allow="autoplay"
                        src={`https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1227735229%3Fsecret_token%3Ds-3c2BT70TfsE&color=%23597fa2&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true`}>
                    </iframe>
                </div>

                <div className="row">
                    <h3 className="music-subtitle music-p">{strings.music.p[0]}</h3>
                </div>
            </div>
            <div className="end-to-end-bg">
                <div className="faq-section container">
                    <h3 className="music-intro">{strings.music.h3[0]}</h3>
                    <div className="music_head">
                        <div className="vertical-section">
                            {qAndAs}
                        </div>
                    </div>
                </div>
            </div>



        </div>
    )
}

export default Music