import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import Article from './children/Article'
import { ArticleCards as articleCards } from '../components/children/ArticleCards'

function Articles() {

    const [selected, setSelected] = useState("All")
    const [selectedCards, setSelectedCards] = useState([])

    let location = useLocation()

    const labels = ["All", "Projects", "Life", "Education"]

    const items = labels.map(label => {
        return (
            <li className={`article_nav-li ${label === selected ? "selected" : ""}`} onClick={addSelected} key={label}>
                {label}
            </li>
        )
    })

    function addSelected(event) {
        // clear "selected" class from each li
        const lineItems = document.getElementsByClassName("article_nav-li")
        Array.from(lineItems).forEach(element => {
            element.classList.remove("selected")
        })

        // add "selected" class to the target
        const { target } = event
        target.classList.add("selected")
        setSelected(target.innerText)
    }

    // make it so that the category of publication determines if it shows up or not
    useEffect(() => {
        setSelectedCards(() => {
            if (selected === "All") {
                return articleCards;
            } else {
                return articleCards.filter(card => card.props.category === selected)
            }
        })
    }, [selected]);

    return (

        <div>
            <div className="container">
                <h1 className="page-header">Articles & Publications</h1>
                <ul className="article_nav">
                    {items}
                </ul>
                <div className="post-cards">
                    {selectedCards}
                </div>
            </div>
            {location.hash ? <Article location={location}/> : ""}
        </div>

    )
}

export default Articles