import React, { useState } from 'react'
import CircleLoader from 'react-spinners/ClipLoader'


import * as strings from '../paragraphs/strings.json'
import { ReactComponent as InstgramSVG } from '../icons/instagram.svg'
import { ReactComponent as SpotifySVG } from '../icons/spotify.svg'

function Podcast() {

    const [isLoading, setLoading] = useState(true)

    const override = `
        position: absolute; 
        // left: calc(1000px/2 - 75px);
        bottom: calc(50% - 75px);
        transform:translate(calc(-50% - 75px), calc(-50% - 75px));
        left: calc(50% - 75px);
    `;


    function replaceSpinner() {
        const page = document.getElementById("podcast")
        setLoading(false)
        page.style.visibility = "visible"
        page.classList.add("fade-in")
    }

    return (
        <div className="home">
            <div className="spacer"></div>
            <div className="podcast-container container">
            <CircleLoader loading={isLoading} css={override} size="150" color="#344657"/>
            <div id="podcast">
                <h1 className="page-header">Podcast</h1>
                <p>{strings.podcast.p}</p>
                <div className="podcast-grid">
                    <div className="podcast-trailer-follow">
                        <div className="podcast-trailer">
                            <iframe
                                title="Wild and Mild Trailer"
                                src="https://open.spotify.com/embed-podcast/episode/0nAEbzHP6m92QC19Ar3RJh"
                                onLoad={replaceSpinner}
                                frameBorder="0"
                                allowtransparency="true"
                                allow="encrypted-media">
                            </iframe>
                        </div>
                        <div className="follow-links">
                            <a href="https://www.instagram.com/wild.and.mildpodcast/" className="follow-link">
                                <h2>Follow on Instagram</h2>
                                <InstgramSVG className="origin" />
                            </a>
                            <a href="https://open.spotify.com/show/60iA6P5JC3apwEkUW0kd69" className="follow-link">
                                <h2>Listen on Spotify</h2>
                                <SpotifySVG className="origin" />
                            </a>
                        </div>
                    </div>

                    <div className="podcast-list">
                        <iframe
                            title="Wild and Mild Playlist"
                            src="https://open.spotify.com/embed/playlist/2a9VflxbcMpeDXHWYvZbX4"
                            frameBorder="0"
                            allowtransparency="true"
                            allow="encrypted-media">
                        </iframe>
                    </div>


                </div>
            </div>
            </div>

            <div className="spacer"></div>
        </div>

    )
}

export default Podcast