import { ReactComponent as InstagramSVG } from '../icons/instagram_small.svg'
import { ReactComponent as GithubSVG } from '../icons/github_small.svg'
import { ReactComponent as SpotifySVG } from '../icons/spotify_small.svg'
import { ReactComponent as LinkedinSVG } from '../icons/linkedin_small.svg'
import { ReactComponent as SoundcloudSVG } from '../icons/soundcloud_small.svg'

function Footer() {
    return (
        <footer>
            <div className="container">
                <div className="row">
                    <a href="https://www.instagram.com/danolanater/"><InstagramSVG /></a>
                    <a href="https://github.com/danolanater" ><GithubSVG /></a>
                    <a href="https://open.spotify.com/show/60iA6P5JC3apwEkUW0kd69" ><SpotifySVG /></a>
                    <a href="https://www.linkedin.com/in/nolanroe/" ><LinkedinSVG /></a>
                    <a href="https://soundcloud.com/danolanater" ><SoundcloudSVG /></a>
                </div>
                <div className="row">
                    <div className="footer-row-container">
                        <p>Website designed and developed by Danolanater LLC</p>
                        <a className="footer-p" href="https://alvarezdesignportfolio.wordpress.com/">Logo by Jonathan Alverez</a>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer